import { Controller } from '@hotwired/stimulus'

const ExerciseBlockFormat = {
  SUPERSET: 1,
  CIRCUIT: 2
}

export default class extends Controller {
  static targets = [
    'format',
    'rounds',
    'workingDuration',
    'restDuration',
    'totalDuration'
  ]

  connect () {
    this.showActiveFields()
  }

  formatChange () {
    this.showActiveFields()
  }

  showActiveFields () {
    const value = parseInt(this.formatTarget.value, 10)
    if (
      value === ExerciseBlockFormat.CIRCUIT ||
      value === ExerciseBlockFormat.SUPERSET
    ) {
      this.roundsTarget.hidden = false
      this.restDurationTarget.hidden = false
      this.totalDurationTarget.hidden = false
    } else {
      this.roundsTarget.hidden = true
      this.restDurationTarget.hidden = true
      this.totalDurationTarget.hidden = true
    }
  }

  getWorkSecs () {
    const mins = parseInt(
      this.workingDurationTarget.querySelector("[name='working_duration_0']")
        .value,
      10
    )
    const secs = parseInt(
      this.workingDurationTarget.querySelector("[name='working_duration_1']")
        .value,
      10
    )
    return mins * 60 + secs
  }

  getRestSecs () {
    const mins = parseInt(
      this.restDurationTarget.querySelector("[name='rest_duration_0']").value,
      10
    )
    const secs = parseInt(
      this.restDurationTarget.querySelector("[name='rest_duration_1']").value,
      10
    )
    return mins * 60 + secs
  }

  formatTimeString (time) {
    const minutes = Math.floor(time / 60).toString()
    const seconds = Math.floor(time % 60)
    if (minutes > 0 && seconds > 0) {
      return `${minutes} minutes ${seconds} seconds`
    } else if (minutes > 0) {
      return `${minutes} minutes`
    } else {
      return `${seconds} seconds`
    }
  }

  calculateTotal () {
    const rounds = this.roundsTarget.querySelector('input').value

    const totalDisplayOutput = this.totalDurationTarget.querySelector('p')
    const total = (this.getWorkSecs() + this.getRestSecs()) * rounds
    totalDisplayOutput.textContent = this.formatTimeString(total)
  }
}
